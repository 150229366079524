import Locale from "../../modules/common/constants/Locale";

/**
 * Path represents path fragments which might be reused by multiple pages.
 * Fragments may be translatable but don't have to be.
 */
export enum Path {
  cockpit = "cockpit",
  postLogin = "post-login",
  magazine = "magazine",
  promotion = "promotion",
  promotions = "promotions",
  search = "search",
  vehicle = "vehicle",
  vehicleComparison = "vehicle-comparison",
  favorites = "favorites",
  savedSearches = "saved-searches",
  dealer = "dealer",
  dealerLocator = "dealer-locator",
  brand = "brand",
  brands = "brands",
  models = "models",
  my = "my",
  yourVehicle = "your-vehicle",
  general = "general",
  pagePreview = "page-preview",
  ffuTestDrive = "ffu-test-drive",
  leasingIntro = "leasing-intro",
  leasingApplication = "leasing-application",
  leasingRequest = "leasing-request",
  vehiclePurchase = "vehicle-purchase",
  vehiclePurchaseConclude = "vehicle-purchase-conclude",
  ffuGeneralInquiry = "ffu-general-inquiry",
  vehicleEvaluationIntro = "vehicle-evaluation-intro",
  vehicleEstimation = "vehicle-estimation",
  ffuVehicleEvaluation = "ffu-vehicle-evaluation",
  tour = "tour",
  checklist = "checklist",
  competitionForm = "competition-form",
  newsletter = "newsletter",
  subscribe = "subscribe",
  matchmaker = "matchmaker",
}

export const UNIVERSAL_PATH = "universal" as const;

type MultiLanguagePath = Record<Locale | typeof UNIVERSAL_PATH, string>;

export const isMultiLanguagePath = (path: string | MultiLanguagePath): path is MultiLanguagePath => {
  return typeof path !== "string";
};
export const PATH_SYNONYMS: Record<Path, string | MultiLanguagePath> = {
  [Path.cockpit]: "cockpit",
  [Path.postLogin]: "post-login",
  [Path.magazine]: {
    de: "magazin",
    fr: "magazine",
    it: "rivista",
    universal: "magazine",
  },
  [Path.promotion]: {
    de: "aktion",
    fr: "action",
    it: "azione",
    universal: "promotion",
  },
  [Path.promotions]: {
    de: "aktionen",
    fr: "actions",
    it: "azioni",
    universal: "promotions",
  },
  [Path.search]: {
    de: "suche",
    fr: "recherche",
    it: "cerca",
    universal: "search",
  },
  [Path.vehicle]: {
    de: "fahrzeug",
    fr: "vehicule",
    it: "veicolo",
    universal: "vehicle",
  },
  [Path.vehicleComparison]: {
    de: "fahrzeugvergleich",
    fr: "comparaison-vehicules",
    it: "confronto-veicoli",
    universal: "vehicle-comparison",
  },
  [Path.my]: "my",
  [Path.favorites]: "favorites",
  [Path.savedSearches]: "saved-searches",
  [Path.dealer]: {
    de: "haendler",
    fr: "concessionnaire",
    it: "concessionaria",
    universal: "dealer",
  },
  [Path.dealerLocator]: {
    de: "haendler-suche",
    fr: "recherche-concessionnaire",
    it: "cerca-concessionaria",
    universal: "dealer-locator",
  },
  [Path.brand]: {
    de: "marke",
    fr: "marque",
    it: "marchio",
    universal: "brand",
  },
  [Path.brands]: {
    de: "marken",
    fr: "marques",
    it: "marche",
    universal: "brands",
  },
  [Path.models]: {
    de: "modelle",
    fr: "modeles",
    it: "modelli",
    universal: "models",
  },
  [Path.pagePreview]: "page-preview",
  [Path.yourVehicle]: {
    de: "ihr-fahrzeug",
    fr: "votre-vehicule",
    it: "vostro-veicolo",
    universal: "your-vehicle",
  },
  [Path.general]: {
    de: "allgemein",
    fr: "general",
    it: "generale",
    universal: "general",
  },
  [Path.ffuTestDrive]: "test-drive",
  [Path.leasingIntro]: "leasing-intro",
  [Path.leasingApplication]: "leasing-application",
  [Path.leasingRequest]: "leasing-request",
  [Path.vehiclePurchase]: "vehicle-purchase",
  [Path.vehiclePurchaseConclude]: "vehicle-purchase-conclude",
  [Path.ffuGeneralInquiry]: "general-inquiry",
  [Path.vehicleEvaluationIntro]: "vehicle-evaluation-intro",
  [Path.vehicleEstimation]: "vehicle-estimation",
  [Path.ffuVehicleEvaluation]: "ffu-vehicle-evaluation",
  [Path.tour]: "tour",
  [Path.checklist]: "checklist",
  [Path.competitionForm]: "competition-form",
  [Path.newsletter]: "newsletter",
  [Path.subscribe]: "subscribe",
  [Path.matchmaker]: "matchmaker",
};

export const getPathForLanguage = (path: Path, language: Locale): string => {
  const pathEntry = PATH_SYNONYMS[path];
  return typeof pathEntry === "string" ? pathEntry : pathEntry[language];
};
